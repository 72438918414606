<script lang="ts">
  import { onMount } from 'svelte'

  import { Popover } from 'carbon-components-svelte'
  import Result from 'carbon-icons-svelte/lib/Result.svelte'
  import Layers from 'carbon-icons-svelte/lib/Layers.svelte'

  import { type ContentId } from 'common/src/types'

  import RelatedContentItem from '../../atoms/content-list-item.svelte'
  import AnimatedItem from './animated-item.svelte'
  import DropArea from '../../atoms/drag-n-drop/drop-area.svelte'
  import Draggable from '../../atoms/drag-n-drop/draggable.svelte'
  import DropHint from '../../atoms/drop-hint.svelte'

  import { state } from '../../../store/state'
  import { handleCopyEvent, ANIMATION_DURATION } from '../../../store/clipboard'
  import MyButton from '../../atoms/my-button.svelte'

  const duration = ANIMATION_DURATION / 1000 + 's'

  let open = false
  let ref: any = null
  let popoverWidth = 34

  $: current = state.clipboard.current
  $: currentClass = $current?.className
  $: items = state.clipboard.stack
  $: state.clipboard.open.set(open)

  let firstSubscribe = true
  let blink = false
  let wholeDropDisabled = true
  let currentDropDisabled = true
  let hintVisible = false
  let hintSelected = false

  const subscriptions: (() => void)[] = []

  const unsubscribe = state.clipboard.stack.listen(() => {
    unsubscribe()

    subscriptions.push(
      state.clipboard.current.listen(() => {
        if (firstSubscribe) {
          firstSubscribe = false
          return
        }

        blink = true
        setTimeout(() => {
          blink = false
        }, 100)
      })
    )
  })

  subscriptions.push(
    state.dragAndDropData.listen(() => {
      const dragging = state.dragAndDropData.get()
      const source = dragging?.source
      const sourceIsAnItem = source?.startsWith('clipboard-item')
      currentDropDisabled =
        (source !== 'clipboard' && !sourceIsAnItem) || dragging?.id === $current?.id || !source
      wholeDropDisabled = source === 'clipboard' || sourceIsAnItem || !source

      setTimeout(() => {
        hintVisible = (dragging || hintSelected) && !open
      }, 50)
    })
  )

  subscriptions.push(
    state.clipboard.open.listen((value) => {
      if (open !== value) {
        open = value
      }
    })
  )

  const onToggleOpen = async () => {
    open = !open
  }

  const handleCopy = async (evt: any) => {
    await handleCopyEvent(evt, state, window)
  }
  const handleCut = async (evt: any) => {
    await handleCopyEvent(evt, state, window)
  }

  const removeAllHandlers = (doc = document) => {
    doc.removeEventListener('copy', handleCopy)
    doc.removeEventListener('cut', handleCut)
  }

  const addRemoveListeners = () => {
    removeAllHandlers()

    document.addEventListener('copy', handleCopy)
    document.addEventListener('cut', handleCut)
  }

  const dropAction = async (content: ContentId) => {
    state.clipboard.parseItem(JSON.stringify(content))
  }

  onMount(() => {
    state.clipboard.initialize()

    addRemoveListeners()
    return () => {
      removeAllHandlers()
      subscriptions.forEach((sub) => sub())
    }
  })

  const replaceCurrent = () => {
    if (multipleItems) {
      $current = $items[0]
      $items = $items.slice(1)
      return
    }

    $current = null
  }

  $: multipleItems = $items.length > 0

  let clipboardPaneHeight = 0
</script>

<div
  style={`
  --animation-duration: ${duration};  
  --popover-width: ${popoverWidth}rem;
  --popover-height: ${clipboardPaneHeight}px;
`}
  bind:this={ref}
  class="clipboard flex items-center justify-center place-content-center ml-auto"
  class:blink
>
  <div class="clipboard-button">
    <MyButton
      kind="ghost"
      className="clipboard-btn"
      onClick={onToggleOpen}
      tooltipPosition="bottom"
      tooltip="Clipboard"
      tooltipAlignment="center"
    >
      <Result size={24} color="white" />
    </MyButton>
  </div>
  <Popover
    id="clipboard-popover"
    class="clipboard-popover"
    align="bottom"
    caret={true}
    bind:open
    on:click:outside={({ detail }) => {
      open = ref.contains(detail.target)
    }}
  >
    <DropArea
      id="whole-current"
      allowedTypes={'all'}
      blackListedSources={['clipboard']}
      disabled={wholeDropDisabled}
      {dropAction}
      backgroundColor="var(--background)"
    >
      <div
        class="clipboard-pane flex flex-col items-center justify-normal p-6 w-full"
        bind:clientHeight={clipboardPaneHeight}
      >
        <p class="self-start font-bold px-2 pb-6">Clipboard</p>
        <div class="w-full flex flex-row items-center justify-normal gap-4 overflow-hidden">
          {#if $current}
            <Draggable
              dragSourceId="clipboard"
              content={$current}
              draggableClasses={`${$currentClass} w-full bg-[var(--hover-color)] p-2 max-h-[6rem]`}
              margin="15px 12px"
            >
              <DropArea
                id="clipboard-current"
                allowedTypes={'all'}
                disabled={currentDropDisabled}
                {dropAction}
                backgroundColor="var(--hover-color)"
              >
                <RelatedContentItem
                  bind:content={$current}
                  remove={replaceCurrent}
                  alwaysShowRemove={true}
                  fontWeight="bold"
                  dragSourceId="clipboard-current"
                />
              </DropArea>
            </Draggable>
          {:else}
            <div class="a-border flex flex-row w-full p-2 min-h-[5rem] text-mediumGray gap-2">
              <div class="a-border min-w-[6rem]"></div>
              <div class="flex justify-around flex-col">
                <div class="font-bold">The clipboard is empty</div>
                <div>The last copied content will appear here, ready to be used</div>
              </div>
            </div>
          {/if}
        </div>

        <div class={`${multipleItems ? 'h-8' : 'h-0'} w-full transition-all duration-300`} />

        <div
          class:pb-2={multipleItems}
          class="list w-full max-h-[28rem] overflow-y-auto overflow-x-hidden flex flex-col gap-2 px-2 transition-all duration-300 h-fit"
          class:empty={!multipleItems}
        >
          {#if !multipleItems}
            <div class="flex flex-col grow items-center justify-center w-full h-full min-h-full gap-4">
              <Layers size={64} color="var(--medium-gray)" />
              <div class="text-mediumGray text-center font-bold px-10">
                Here you'll find all the items you've previously copied. You can delete those you're no longer
                interested in or select one to set as the current item.
              </div>
            </div>
          {/if}
          {#each $items as item}
            <AnimatedItem {item} {state} classes={item.className} />
          {/each}
        </div>
      </div>
    </DropArea>
  </Popover>
  {#if hintVisible}
    <DropHint
      dropAction={(content) => {
        hintSelected = true
        dropAction(content)
        setTimeout(() => {
          hintSelected = false
          hintVisible = false
        }, 400)
      }}
      hidden={false}
      text="Clipboard"
      selected={hintSelected}
    />
  {/if}
</div>

<style lang="postcss">
  @import './animations.css';

  .clipboard :global(.bx--popover-contents) {
    top: 0.1rem;
    right: 12.1rem;
  }

  .clipboard {
    position: relative;
  }

  .clipboard.blink :global(button > svg) {
    @apply transition-all duration-100 scale-125;
  }

  .clipboard :global(.bx--popover-contents) {
    transform: translateY(-0.2rem);
    min-width: var(--popover-width);
    max-width: var(--popover-width);
    max-height: var(--popover-height);
  }

  .clipboard :global(.bx--popover--caret.bx--popover--bottom .bx--popover-contents::after),
  .clipboard :global(.bx--popover--caret.bx--popover--bottom .bx--popover-contents::before) {
    visibility: hidden;
  }

  .clipboard :global(bx--popover--bottom) {
    left: unset;
    transform: unset;
  }

  .clipboard-button :global(.bx--btn--ghost) {
    background-color: var(--accent);
  }

  .current {
    border: 0.1rem solid var(--accent);
    overflow-y: hidden;
  }

  .move-down {
    animation-name: slideInTop;
    animation-duration: var(--animation-duration);
  }
  .list.empty {
    min-height: 14rem;
  }

  .a-border {
    border: 1px solid var(--medium-gray);
  }

  .clipboard :global(.drop-hint) {
    position: absolute;
    transform: translate(0px, 80px);
  }

  .clipboard :global(.drop-hint .drop-area) {
    position: absolute;
  }

  .clipboard :global(.clipboard-btn > .my-tooltip > div > .bx--tooltip--shown) {
    bottom: -47px;
    margin-inline-start: 12px;
    min-width: fit-content;
  }
</style>
