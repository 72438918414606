<script lang="ts">
  import { onDestroy } from 'svelte'
  import { Popover } from 'carbon-components-svelte'
  import Notification from 'carbon-icons-svelte/lib/Notification.svelte'

  import type { Content } from 'common/src/types'
  import ListItem from '../../atoms/content-list-item.svelte'
  import MyButton from '../../atoms/my-button.svelte'
  import TimeAgo from '../../atoms/time-ago.svelte'
  import MyBadge from '../../atoms/my-badge.svelte'
  import FakeLink from '../../../style/fake-link.svelte'

  import { state } from '../../../store/state'
  import { router } from '../../../routing/router'
  import { NullUser, User } from '../../../store/user'

  const duration = 500 / 1000 + 's'

  let ref: any = null
  let popoverWidth = 34

  let blink = false
  let open = false
  let count = 0
  let recent: Content[] = []
  let lastRead = 0
  let loggedUser: User = new NullUser()

  const onToggleOpen = () => {
    open = !open

    if (recent.length === 0) {
      return
    }

    const target = recent[0].lastModifiedDate
    if (!open && target > lastRead) {
      state.settings.upsert({ lastRead: target })
      doTheMath()
    }
  }

  const letTheClipboardDropHintAppear = () => {
    setTimeout(() => {
      open = false
    }, 100)
  }

  const doBlink = () => {
    blink = true
    setTimeout(() => {
      blink = false
    }, 100)
  }

  const newer = (item: Content) => item.lastModifiedDate > lastRead && item.modifierId !== loggedUser.userId

  const doTheMath = () => {
    const actual = state.mostRecentContents.get()
    lastRead = state.settings.data.get().lastRead || 0

    const newContents = actual.filter(newer)

    if (newContents.length > 0) {
      doBlink()
    }

    count = newContents.length
    recent = actual

    if (recent.length === 0) {
      return
    }
  }

  const subscriptions = [
    state.dragAndDropData.listen(letTheClipboardDropHintAppear),
    state.mostRecentContents.subscribe(doTheMath),
    state.loggedUser.subscribe((user) => {
      loggedUser = user
    }),
  ]

  onDestroy(() => {
    subscriptions.forEach((sub) => sub())
  })

  let notificationsPaneHeight = '728px'
</script>

<div
  style={`
    --animation-duration: ${duration};  
    --popover-width: ${popoverWidth}rem;
    --popover-height: ${notificationsPaneHeight};
  `}
  bind:this={ref}
  class="notifications flex items-center justify-center place-content-center ml-auto"
  class:blink
>
  <div class="notifications-button">
    <MyButton
      kind="ghost"
      className="notifications-btn"
      onClick={onToggleOpen}
      tooltipPosition="bottom"
      tooltip="Something might interest you..."
      tooltipAlignment="center"
    >
      <Notification size={24} color="white" />
      <div class="absolute mt-[-1rem] ml-[0.8rem]" class:hidden={count === 0}>
        <MyBadge {count} scale={0.8} backgroundColor={'var(--primary)'} />
      </div>
    </MyButton>
  </div>
  <Popover
    id="notifications-popover"
    class="notifications-popover"
    align="bottom"
    caret={true}
    bind:open
    on:click:outside={({ detail }) => {
      const shouldOpen = ref.contains(detail.target)
      if (shouldOpen === open) {
        return
      }

      onToggleOpen()
    }}
  >
    <div class="notifications-root flex flex-col gap-2 p-6">
      <div class="header flex flex-row">
        <p class="self-start font-bold px-2 pb-6">Latest updates</p>
        <p class="flex-grow"></p>
        <FakeLink
          ><button
            on:click={() => {
              router(state).navigate('#/saved-search')
            }}>Saved searches</button
          ></FakeLink
        >
      </div>
      <div class="items-container flex flex-col gap-2 p-2 overflow-y-auto">
        {#each recent as item}
          <div class="flex flex-row relative" class:unread={newer(item)}>
            <ListItem content={item} alwaysHideRemove={true} dragSourceId="notifications" />
            <div class="time-ago-container absolute right-0">
              <TimeAgo timestamp={item.lastModifiedDate} />
            </div>
          </div>
        {/each}
      </div>
    </div>
  </Popover>
</div>

<style lang="postcss">
  .notifications-root {
    background-color: var(--background);
  }
  .notifications :global(.bx--popover-contents) {
    top: 0.1rem;
    right: 8.5rem;
  }

  .notifications {
    position: relative;
  }

  .notifications.blink :global(button > svg) {
    @apply transition-all duration-100 scale-125;
  }

  .items-container {
    max-height: calc(var(--popover-height) - 10rem);
  }

  .notifications :global(.bx--popover-contents) {
    transform: translateY(-0.2rem);
    min-width: var(--popover-width);
    max-width: var(--popover-width);
    max-height: var(--popover-height);
  }

  .notifications :global(.bx--popover--caret.bx--popover--bottom .bx--popover-contents::after),
  .notifications :global(.bx--popover--caret.bx--popover--bottom .bx--popover-contents::before) {
    visibility: hidden;
  }

  .notifications :global(bx--popover--bottom) {
    left: unset;
    transform: unset;
  }

  .notifications-button :global(.bx--btn--ghost) {
    background-color: var(--accent);
  }

  .notifications :global(.notifications-btn > .my-tooltip > div > .bx--tooltip--shown) {
    bottom: -47px;
    margin-inline-start: 12px;
    min-width: fit-content;
  }

  .unread {
    background-color: var(--hover-color);
    padding: 0.5rem;
  }

  .unread .time-ago-container {
    margin-inline-end: 0.5rem;
  }
</style>
