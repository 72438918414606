<script>
  export let count = 0
  export let scale = 1
  export let backgroundColor = 'red'

  $: displayText = count > 99 ? '99+' : count.toString()
  $: width = displayText.length <= 1 ? 20 : displayText.length * 9 + 6
  $: radius = width / 2
  $: textX = width / 2

  const height = 20
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  width={width * scale}
  height={height * scale}
  viewBox="0 0 {width} {height}"
>
  <rect x="0" y="0" {width} {height} rx={radius} ry={radius} fill={backgroundColor} />

  <text
    x={textX}
    y="11"
    font-size="12"
    font-weight="bold"
    fill="white"
    text-anchor="middle"
    alignment-baseline="central"
  >
    {displayText}
  </text>
</svg>
