<script lang="ts">
  import { onMount } from 'svelte'

  import ChevronDown from 'carbon-icons-svelte/lib/ChevronDown.svelte'
  import { Popover } from 'carbon-components-svelte'

  import { type Content } from 'common/src/types'

  import MyButton from '../../atoms/my-button.svelte'
  import { state } from '../../../store/state'
  import NewContentTab from '../../content/new-content/new-content-tab.svelte'
  import { SizeObserver } from '../../atoms/size-observer'
  import Tab from './tab.svelte'
  import { TabsVisibility } from './tabs-visibility'

  const ADJUSTMENT = 8 // just lowering by 1 will break the layout

  const isLoading = state.isLoading
  const allTabs = state.tabs.tabs
  const selectedTab = state.tabs.selected

  let ref: any
  let open = false

  const tabsVisibility = new TabsVisibility(state.tabs)

  const { visibleTabs, hiddenTabs } = tabsVisibility

  const unsubscribeSelectedTab = selectedTab.subscribe(() => tabsVisibility.moveSelectedTab())

  const unsubscribeAllTabs = allTabs.listen(() => tabsVisibility.updateContainerWidth())

  const unsubscribeLastModified = state.lastModified.listen((content: Content) => {
    state.tabs.updateText(content.id, state.kindFromType(content.type).getTitle(content))
  })

  const observer = new SizeObserver()

  onMount(() => {
    observer.attach(ref, (size: { width: number; height: number }) => {
      tabsVisibility.updateContainerWidth(size.width - ADJUSTMENT)
    })

    return () => {
      unsubscribeSelectedTab()
      unsubscribeAllTabs()
      unsubscribeLastModified()
      observer.detach()
    }
  })
</script>

<NewContentTab />
<div class="tabs-container" bind:this={ref}>
  <div class="w-fit flex items-end">
    {#if !$isLoading}
      {#each $visibleTabs as tab (tab.contentId)}
        <Tab
          props={{
            contentId: tab.contentId,
            iconId: tab.iconId,
            btnText: tab.text,
            contentType: tab.contentType,
          }}
        />
      {/each}
    {/if}
  </div>
  {#if !$isLoading}
    <div class="upper-right-buttons w-[50px]">
      {#if $hiddenTabs.length > 0}
        <MyButton
          kind="ghost"
          className="tabs-btn {open ? 'show-hidden' : ''}"
          onClick={() => {
            open = !open
          }}
          tooltipPosition="bottom"
          tooltip="Show hidden tabs"
          tooltipAlignment="end"
        >
          <ChevronDown color={'var(--hover-color)'} />
        </MyButton>
        <Popover
          class="tabs-popover"
          align="bottom"
          bind:open
          on:click:outside={({ detail }) => {
            open = ref.contains(detail.target)
          }}
        >
          {#each $hiddenTabs as tab (tab.contentId)}
            <Tab
              props={{
                contentId: tab.contentId,
                iconId: tab.iconId,
                btnText: tab.text,
                contentType: tab.contentType,
              }}
            />
          {/each}
        </Popover>
      {/if}
    </div>
  {/if}
</div>

<style lang="postcss">
  .tabs-container {
    display: flex;
    flex-direction: row;
    min-height: var(--bars-size);
    height: var(--bars-size);
    max-height: var(--bars-size);
    min-width: calc(100% - 416px);
    flex-grow: 1;
    background-color: var(--accent);
    justify-content: space-between;
  }

  .tabs-container :global(.tabs-btn > .my-tooltip > div > .bx--tooltip--shown) {
    bottom: -47px;
    margin-inline-start: 12px;
    min-width: fit-content;
  }

  .tabs-container :global(.tabs-btn.show-hidden > svg) {
    @apply rotate-180;
  }

  .tabs-container :global(.tabs-popover) {
    transform: unset;
    top: var(--bars-size);
    right: 0;
    left: unset;
  }

  .tabs-container :global(.bx--popover-contents > .tab-container > div > .bx--btn) {
    color: black;
  }

  .tabs-container :global(.bx--popover-contents > .tab-container > div > .bx--btn > button > svg > path) {
    fill: black;
  }

  .tabs-container :global(.bx--popover-contents .cls-1),
  .tabs-container :global(.bx--popover-contents .cls-2) {
    stroke: black;
  }

  .tabs-container :global(.tabs-popover .tab-container button.bx--btn:hover) {
    background-color: var(--hover-color);
  }
</style>
